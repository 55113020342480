html, 
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: clip;
}

.jumbotron {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

#hero {
  margin: 0;
  padding: 0;
}

h1,
h2,
#Projects,
#About,
#Contact {
    font-family: 'Montserrat', sans-serif;
}

#skills {
  width: 50%;
  height: auto;
}

.skillsImg {
  width: 30%;
  height: auto;
  display: flex;
  justify-content: center;
}

.skillsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.techStackContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5em;
}

.frontEndContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 5%;
}

.techStack {
  display: flex;
  justify-content: center;
  border: 2px solid #272341;
  border-radius: 4px;
  padding: 20px;
  margin: 3px;
  font-size: 1.1rem;
  color: #fff;
  background-color: rgba(67,85,82,.576471);
  filter: drop-shadow(4px 4px 1px rgba(67,85,82,.576471));
}

#Projects {
  display: block;
  margin-top: -15rem;
  padding: 18rem 0 10rem;
  margin-bottom: -15rem;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.project-wrapper {
  margin-bottom: 15rem;
}

.project-description {
  font-size: 18px;
}

.project-wrapper-description {
  text-align: right;
}

.row {
  margin-bottom: 8rem;
  margin-left: 10rem;
  margin-right: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 30px;
}

.project-columns {
  position: relative;
  width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

.project-title {
  font-weight: 700;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.project-text {
  text-align: left;
}

.tech {
  border: 1px solid #272341;
  padding: 4px;
  margin: 2px;
  font-size: 0.95rem;
  color: #fff;
  background-color: rgba(67,85,82,.576471);
}

.button {
    position: relative;
    display: block;
    font-size: 18px;
    letter-spacing: 2px;
    border: 2px #00cdac solid;
    border-radius: 4px;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
  }

.button:before {
  content: "";
  position: absolute;
  background: #009c9b;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  -webkit-transition: all 0.2s linear;
}
  
.button:hover:before {
  top: 0;
}

.project-image {
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}

iframe {
  width: 660px;
  height: 390px;
  border: 2px solid rgba(67,85,82,.576471);
}

h3 {
  display: flex;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.expressIcon {
  width: 50px;
  height: 50px;
}

.collaborators {
  margin-bottom: 0.5rem;
  text-align: left;
}

.project-description {
  margin-bottom: 0.5rem;
  text-align: left;
}

.project-button {
  position: relative;
  float: left;
  margin-top: 1em;
  line-height: 1;
  padding: 0.8rem;
  font-size: 15px;
  color:#00cdac;
  border: 2px #009c9b solid;
}

.project-button:before {
  content: "";
  position: absolute;
  background: #009c9b;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  -webkit-transition: all 0.1s ease-in;
}

.project-button:hover:before {
  top: 0;
}

/* .project-text {
  display: inline-block;
  position: relative;
  padding: 0.8rem;
  line-height: 1;
  z-index: 1;
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1)
} */

.fleetImg,
.houseOfHorrorsImg,
.clinicFlow {
  width: 660px;
  height: 390px;
  border: 2px solid rgba(67,85,82,.576471);
}

.resume-button {
  position: relative;
  display: block;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 18px;
  letter-spacing: 3px;
  border: 2px #fffff2 solid;
  color:#fff;
}

.resume-button:before {
  content: "";
  position: absolute;
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  -webkit-transition: all 0.1s ease-in;
}

.resume-button:hover:before {
  top: 0;
}

.resume-button:hover {
  color:#00cdac;
}

form {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: start;
}

.messageBox {
  width: 100%;
  height: auto;
}

.submitButton {
  position: relative;
  margin-left: 0.6rem;
  display: block;
  font-size: 18px;
  letter-spacing: 2px;
  border: 2px #00cdac solid;
  border-radius: 4px;
  outline: 0;
  overflow:hidden;
}

.submitButton:before {
  content: "";
  position: absolute;
  background: linear-gradient(#009c9b, #00cdac);
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  -webkit-transition: all 0.1s ease-in;
}

.submitButton:hover:before {
  top: 0;
}

@media (max-width: 1280px) {
  .row, 
  .skillsContainer {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .fleetImg,
  .houseOfHorrorsImg,
  .clinicFlow {
    height: auto;
    width: 50%;
  }
}

@media (max-width: 1100px) {
  .row, 
  .skillsContainer {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .fleetImg,
  .houseOfHorrorsImg,
  .clinicFlow {
    height: auto;
    width: 50%;
  }
}

@media (max-width: 992px) {
  .row, 
  .skillsContainer {
    display: flex;
    flex-direction: column;
  }
  .fleetImg,
  .houseOfHorrorsImg,
  .clinicFlow,
  .skillsImg {
    height: auto;
    width: 50%;
  }
  .frontEndContainer {
    width: 100%;
    height: auto;
    display: flex;
    align-content: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .row, 
  .skillsContainer {
    display: flex;
    flex-direction: column;
  }
  #navSkills {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
  }
  .fleetImg,
  .houseOfHorrorsImg,
  .clinicFlow,
  .skillsImg {
    height: auto;
    width: 50%;
  }
}

@media (max-width: 600px) {
  .row {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .skillsContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }
  .fleetImg,
  .houseOfHorrorsImg,
  .clinicFlow,
  .skillsImg {
    height: auto;
    width: 50%;
  }
  #navSkills {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 450px) {
  .fleetImg,
  .houseOfHorrorsImg,
  .clinicFlow,
  .skillsImg {
    height: auto;
    width: 50%;
  }
  .project-columns {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .title {
    font-size: 2em;
  }
  #navSkills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 390px) {
  .project-columns {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .fleetImg,
  .houseOfHorrorsImg,
  .clinicFlow,
  .skillsImg {
    height: auto;
    width: 100%;
  }
  .techStack {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    width: 46%;
    height: auto;
  }
  #navSkills {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 55px;
    padding-right: 55px;
  }
}
