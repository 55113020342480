* {
    margin: 0;
    box-sizing: 0;
}

h1 {
    font-family: 'Montserrat', sans-serif;
}

.socials {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 1.rem;
    gap: 4.3rem;

}

/* --- Navbar and burger menu --- */

nav {
    width: 100%;
    height: 5em;
    /* background-color: rgb(37, 39, 53); */
    display: flex;
    justify-content: flex-start;
    padding: 1em;
}

.burger_menu {
	position: absolute;
	top: 24px;
	left: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 30px;
	height: 20px;
	cursor: pointer;
	z-index: 1001;
}

.burger_menu div {
	width: 100%;
	height: 3px;
	background-color: #00cdac;
	transition: all 0.3s ease;
}

/* --- sliding menu --- */

.hidden {
    display: none;
}

.visible {
    display: inherit;
}

/* --- animations --- */

.burger_menu.open div:nth-child(1) {
	transform: translateY(8px) rotate(45deg);
}

.burger_menu.open div:nth-child(2) {
	opacity: 0;
}

.burger_menu.open div:nth-child(3) {
	transform: translateY(-8px) rotate(-45deg);
}

/* --- unclicked --- */

.burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

/* --- mobile-menu --- */

.mobile-menu {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	left: -100%;
	position: fixed;
	top: 0;
	transition: all 0.5s ease-in-out;
	width: 100%;
	z-index: 1000;
}

.mobile-menu.open {
	left: 0;
}

.mobile-menu ul {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	font-size: 24px;
	text-align: center;
}
.mobile-menu li:hover {
	transition: color 0.4s;
	font-size: 28px;
}

@media screen and (max-width: 1140px) {
	.navLink {
		display: none;
	}
}

@media screen and (min-width: 451px) {
	.burger_menu {
		display: none;
	}
}

