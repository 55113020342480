h1 {
    font-family: 'Montserrat', sans-serif;
}

nav {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    gap: 4rem;
    padding-top: 2rem;
    padding-right: 2rem;
}
