.scrollUpButton {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background-color: #aaaaaa;
	border: none;
	border-radius: 50%;
	width: 90px;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s, visibility 0.3s;
	z-index: 1001;
	font-size: 40px;
}

.scrollUpButton.visible {
	opacity: 1;
	visibility: visible;
}

.scrollUpButton:hover {
	background-color: #9a9a9a;
	cursor: pointer;
}
